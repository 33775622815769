import { ReactElement } from 'react';

import withData from '../components/lib/hocs/with-data';
import AuthCardLayout from '../components/lib/AuthCardLayout';
import Login from '../components/login';

const LoginPage = (): ReactElement => (
  <AuthCardLayout
    leftSideContent={
      <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
        Need help to onboard?
        <a className="text-underline text-300 ml-1" href="mailto:team@zenvest.com">
          Contact us
        </a>
      </p>
    }
  >
    <Login />
  </AuthCardLayout>
);

export default withData(LoginPage);
