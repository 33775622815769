import { ReactElement } from 'react';

import LoginButton from './login-button';

const Login = (): ReactElement => (
  <div className="text-center">
    <img className="d-block mx-auto mb-4" src="/img/logo.svg" alt="logo" />
    <h4>Welcome to Zenvest</h4>
    <LoginButton />
    <style jsx>
      {`
        img {
          width: 70px;
        }
      `}
    </style>
  </div>
);

export default Login;
