import { ReactElement } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { stringify } from 'querystring';
import { randomBytes } from 'crypto';
import base64url from 'base64url';

const LoginButton = (): ReactElement => {
  const {
    query: { email, roleId, redirectTo = '/' },
  } = useRouter();
  return (
    <Button
      color="primary"
      size="md"
      className="mt-3"
      onClick={() => {
        const nonce = randomBytes(48).toString('base64');
        localStorage.setItem(nonce, JSON.stringify({ redirectTo, roleId, fromSignup: false }));
        window.location.assign(
          `/api/auth/login?${stringify({
            email,
            signup: false,
            title: 'Zenvest',
            logo: 'https://me.zenvest.com/img/logo.png',
            state: base64url.encode(nonce),
          })}`,
        );
      }}
    >
      <FontAwesomeIcon icon="sign-in-alt" transform="shrink-4 down-1" className="mr-1" />
      Login to Zenvest
    </Button>
  );
};

export default LoginButton;
